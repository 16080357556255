/** @jsx jsx */
import { jsx, Box, Grid, Container, Text, IconButton } from 'theme-ui';
import { GiAmmoniteFossil } from 'react-icons/gi';

import SectionHeader from './section-header';
import { assGoals } from 'content/about.js'

export default function AssTarget({ lang = 'it' }) {
  return (
    <Container sx={styles.container}>
      <SectionHeader
        slogan={assGoals[lang]?.slogan}
        title=""
      />
      <Grid sx={styles.grid}>
        {assGoals[lang]?.goals.map((item) => (
          <Box sx={styles.card} key={item.id}>
            <IconButton sx={styles.img} aria-label="list icon">
              <GiAmmoniteFossil />
            </IconButton>
            <Box sx={styles.wrapper}>
              <Text sx={styles.wrapper.subTitle}>{item}</Text>
            </Box>
          </Box>
        ))}
      </Grid>
    </Container>
  );
}

const styles = {
  container:{
    mt: [5, null, null, null, 7]
  },
  grid: {
    pt: [0, null, null, null, null, null, null, 3],
    gridGap: [
      '37px 0',
      null,
      '45px 30px',
      null,
      '25px 20px;',
      null,
      null,
      '90px 70px',
    ],
    width: ['100%', '80%', '100%'],
    mx: 'auto',
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(3,1fr)',
    ],
  },
  card: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  img: {
    width: [25, '35px'],
    height: 'auto',
    color: 'secondary',
    padding: 0,
    fontSize: [2, 5],
    marginLeft: '-1px',
    flexShrink: 0,
    justifyContent: 'flex-start',
    marginTop: [1, '2px'],
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    mt: '-5px',
    title: {
      fontSize: [3, null, null, null, null, 4],
      color: 'heading_secondary',
      lineHeight: 1.4,
      fontWeight: 700,
      mb: [2, null, null, null, null, 3],
      mt: '2px',
    }
  },
};
