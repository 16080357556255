
import museo from '../assets/picSlider/museo.jpg';
import sala1 from '../assets/picSlider/sala1.jpg';
import ammoniti from '../assets/picSlider/ammoniti.jpg';
import sala2 from '../assets/picSlider/sala2.jpg';
import dinoWood from '../assets/picSlider/dinoWood.jpg';
import caveBear from '../assets/picSlider/caveBear.jpg';

export const data = {
  gallery: [
    {
      id: 1,
      image: museo,
      title: {
        it: 'ATTRAVERSO LE ERE GEOLOGICHE',
        en: 'THROUGH GEOLOGICAL TIMES'
      },
      desc: {
        it: `Si possono ammirare fossili ritrovati in Lessinia e in molti altri luoghi della Terra in un percorso 
        espositivo che offre uno sguardo sull’evoluzione della vita nelle varie ere geologiche.`,
        en: `The museum exhibits fossils discovered in the Lessini Mountains and in many other locations 
        offering a journey through the evolution of life in the different geologic times.`
      }
    },
    {
      id: 2,
      image: ammoniti,
      title: {
        it: 'LE AMMONITI',
        en: 'THE AMMONITES'
      },
      desc: {
        it: `Nel museo è esposta un’ampia collezione di ammoniti provenienti sia dai monti Lessini che da diversi 
        altri luoghi del mondo. Le ammoniti sono conchiglie a spirale di molluschi cefalopodi simili agli 
        attuali nautilus. Tali organismi sono vissuti dal Devoniano (400 milioni di anni fa) fino alla loro 
        estinzione avvenuta insieme ai dinosauri nel Cretaceo (65 milioni di anni fa). Grazie alla grande passione di Attilio che 
        assieme ai suoi collaboratori ne ha trovato e studiato tante, alcune portano il suo nome in quanto 
        nuovi generi e specie scoperte durante le sue ricerche.`,
        en: `The museum displays a large collection of ammonites from both the Lessini Mountains and also
        from several other places in the world. Ammonites are spiral shells of cephalopod molluscs
        similar to the current nautilus. These organisms lived from the Devonian (400 million of years
        ago) until their extinction together with the dinosaurs in the Cretaceous (65 million of years
        ago). Attilio together with his collaborators has found and studied several thousands of these
        fossils, with some of them carryinghis name as new genres and species discovered in the Lessini area.`
      }
    },
    {
      id: 3,
      image: sala1,
      title: {
        it: 'I FOSSILI DELLA LESSINIA',
        en: 'FOSSILS FROM THE LESSINI'
      },
      desc: {
        it: `Oltre alle ammoniti, si sono conservati nelle rocce della Lessinia tante altre tipologie di
        animali e piante fossili esposte nel museo come il brachiopode il
        Lessinorhynchia benetti classificato dagli studiosi come nuovo genere e specie e dedicato allo scopritore Attilio Benetti.`,
        en: `In addition to ammonites, many other types of fossilized
        animals and plants have been preserved
        in the rocks of Lessini Mountains and are featured in the museum. These include the brachiopod
        Lessinorhynchia benetti, classified by scientists as a new genus and species dedicated to the
        discoverer Attilio Benetti.`
      }
    },
    {
      id: 4,
      image: sala2,
      title: {
        it: ' RETTILI MARINI',
        en: 'MARINE REPTILES'
      },
      desc: {
        it: `Nel Giurassico e nel Cretaceo, mentre i dinosauri abitavano la terra emersa, le rocce della Lessinia si formavano dai sedimenti (o anche dai fanghi) deposti sui 
        fondali di un mare tropicale. Resti di rettili marini come gli ittiosauri sono esposti nel
      museo, insieme ad una delle piste di dinosauro meglio conservate delle prealpi: un dinosauro
      carnivoro bipede icnogenere Kayentapus che ha impresso le sue orme su particolarissimi depositi calcarei 
      circa 200 milioni di anni fa, e ritrovate oggi nella zona della Bella Lasta, negli alti Lessini.`,
        en: `In the Jurassic and Cretaceous times, when the dinosaurs inhabited the land, the Lessini were at
        the bottom of a tropical sea. Remains of marine reptiles such as ichthyosaurs are exhibited in the
        museum, along with one of the best preserved dinosaur tracks in the pre-Alps found recently in
        the Bella Lasta area of the Lessini. The therapod carnivorous dinosaur Kayentapus has left two
        footsteps on a muddy beach that the geologic time has transformed into a solid limestone rock.`
      }
    },
    {
      id: 5,
      image: dinoWood,
      title: {
        it: 'IL DINOSAURO ICARUS',
        en: 'THE ICARUS DINOSAURS'
      },
      desc: {
        it: `Dalle impronte fossili gli studiosi riescono a stabilire il tipo di dinosauro che le ha lasciate, il
        suo peso e le sue dimensioni. Da questi dati l’artista scultore Mauro Lampo Olivotto
        ci ha preparato una ricostruzione in legno del dinosauro Kayentapus alla sua grandezza naturale di 5 metri, 
        che domina dall’alto le “sue impronte” e gli altri fossili.`,
        en: `From fossil footprints, scientists have been able to establish the morphology of the dinosaur that
        left them. From theses data the artist Mauro Lampo Olivotto has sculptured a wooden
        reconstruction of the Kayentapus dinosaur reaching 5 meters in size.`
      }
    },
    {
      id: 6,
      image: caveBear,
      title: {
        it: 'ORSO DELLE CAVERNE',
        en: 'CAVE BEAR'
      },
      desc: {
        it: `Nel museo è esposto uno scheletro completo di orso delle caverne, Ursus Spelaeus, oggi estinto, 
        che in posizione eretta supera i 3 metri di altezza. Tale scheletro fu scoperto negli anni 50 da 
        Attilio in alcune nuove diramazioni delle grotte dei Covoli di Velo, oltre ai resti di altri animali 
        tra cui una mascella di cucciolo di leone delle caverne, ossa di iena, lupi, framme nti di zanne e 
        denti di elefanti che vivevano in Lessinia durante l’alternarsi degli eventi glaciali avvenuti nel quaternario.`,
        en: `The exhibition includes a complete skeleton of Ursus spelaeus, a now extinct cave bear, standing
        in an upright position over 3 meters high. This skeleton was discovered in the 50s by Attilio in
        some new branches of the Covoli di Velo caves, as well as other remains of animals including a
        jaw of a cave lion cub, hyena bones, wolves, fragments of tusks and teeth of elephants that lived
        in Lessinia during the alternation of the Quaternary glaciations.`
      }
    }
  ],
};