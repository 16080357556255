/** @jsx jsx React*/
import React from 'react';
import { jsx, Container, Text, Box, Image } from 'theme-ui';
import SectionHeader from '../components/section-header'
import { camposilvano, trail, cave } from 'content/where.js';
import TextFeature from '../components/text-feature';
import trailPic from '../assets/images/covolo.jpg'
import cavePic from '../assets/images/covoloIn.jpg'

export default function Camposilvano({ lang = 'it' }) {
  return (
    <>
      <Container sx={styles.containerBox}>
        <SectionHeader
          slogan={camposilvano.title[lang]}
          title=""
        />
        <Text sx={styles.description}>
          {camposilvano.text[lang]}
        </Text>

      </Container>
      <Container sx={styles.sectionWithPicture}>
        <Box sx={styles.sectionWithPicture.imageBox} >
          <Image src={trailPic} alt="covolo-trail" />
        </Box>
        <Box sx={styles.sectionWithPicture.contentBox}>
          <TextFeature subTitle={trail.title[lang]} title='' />
          <Text>
            {trail.text[lang]}
          </Text>
        </Box>
      </Container>
      <Container sx={styles.sectionWithPicture}>
        <Box sx={styles.sectionWithPicture.contentBox}>
          <Text>
            {cave.text[lang]}
          </Text>
        </Box>
        <Box sx={styles.sectionWithPicture.imageBox}>
          <Image src={cavePic} alt="covolo-cave" />
        </Box>
      </Container>
    </>
  );
}

const styles = {
  containerBox: {
    mt: [8, null, null, null, 9],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: ['wrap', null, null, 'nowrap'],
  },
  description: {
    mb: 7
  },
  sectionWithPicture: {
    display: 'flex',
    // my: 8,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: ['column', null, null, 'row'],
    contentBox: {
      width: ['100%', null, null, '60%'],
      padding: 5
    },
    imageBox: {
      display: 'block',
      justifyContent: 'center',
      overflow: 'hidden',
      textAlign: 'right',
      width: ['100%', '40vw'],
      '> img': {
        borderRadius: 10,
        height: 'auto',
      },
    },
  }
};

