export const mainData = {
  it: {
    subTitle: 'Chi siamo',
    title: 'Associazione culturale Benetticeras APS',
    par1: `L'Associazione nasce nel giugno 1997 per volontà del fondatore Cav. Attilio Benetti con il nome
  originale di Associazione Museo dei Fossili della Lessinia. Attilio Benetti è stato un ricercatore
  riconosciuto a livello internazionale e curatore della collezione che costituisce l’attuale Museo
  Geopaleontologico di Camposilvano.`,
    par2: `L'Associazione si prefigge la conservazione e la promozione dei musei e dei siti
  naturali di interesse
  geologico, paleontologico, preistorico della Lessinia e del Veneto. Ha iniziato la sua attività con lo
  sviluppo e la gestione del Museo Geopaleontologico di Camposilvano e della Grotta del Covolo,
  struttura didattica fondata
  dal Cav.
  Attilio Benetti nel 1997. Da allora ha svolto iniziative di
  salvaguardia, promozione e ricerca del patrimonio geologico in collaborazione con l'Ente Parco
  Regionale della Lessinia e i comuni del territorio, anche
  attraverso la gestione di altri siti e musei,
  come il Museo Paleontologico e Preistorico di Sant'Anna d'Alfaedo. Dal 2020 l'associazione ha
  iniziato un nuovo percorso per ampliare il proprio campo di azione, avviandosi a diventare
  APS e
  cambiando il proprio nome in Associazione Culturale Benetticeras (denominazione
  entrata
  in vigore
  dal 18
  dicembre 2020
  con l’approvazione di un nuovo statuto).
  Il nuovo nome dell’Associazione si
  ispira al
  socio fondatore scomparso “Attilio Benetti” (1923
  -
  2013), il quale si è impegnato in tutti i
  campi della ricerca e della promozione del Patrimonio Culturale e Ambientale della Lessinia, dalla
  Geologia, alle Scienze Naturali, alla Storia, alle tradizioni. Il nuovo genere di ammonite a lui
  intitolato, “Benetticeras”, rappresenta simbolicamente l’interesse a 360°, il testamento spirituale e
  il patrimonio che Egli ci ha trasmesso e che l’Associazione si impegna a valorizzare e a incrementare
  nei confronti sia degli abitanti della Lessinia, sia dei turisti ed escursionisti.`
  },
  en: {
    subTitle: 'About Us',
    title: 'Benetticeras APS Cultural Association',
    par1: `The Association was founded in June 1997 by the will of the Attilio Benetti with the original name of
    Association Fossils Museum of Lessinia. Attilio Benetti was an internationally recognized researcher
    and curator of the collection that constitutes the current Geopaleontological Museum of Camposilvano`,
    par2: `The Association aims at the preservation and promotion of museums and natural sites of
    geological, paleontological, prehistoric interest of Lessinia and Veneto. It started its activity with the
    development and management of the Geopaleontological Museum of Camposilvano and of the
    Covolo Cave, a didactic structure founded by Attilio Benetti in 1997. Since then it has carried out
    initiatives of safeguard, promotion and research of the geological heritage, in collaboration with the 
    Regional Authority Park of Lessinia (Ente Parco Regionale della Lessinia) and the municipalities
    of the area, also through the management of other sites and museums, such as the
    Paleontological and Prehistoric Museum of Sant'Anna d'Alfaedo. Since 2020, the association has
    started a new path to expand its field of action, starting to become APS
    (Associazione di Promozione Sociale, Association for Social Promotion) and changing its name to Associazione Culturale 
    Benetticeras ( Cultural Association Benetticeras, denomination in force since December 18, 2020 with the 
    approval of a new statute). The new name of the Association is inspired by the
    deceased founding member Attilio Benetti (1923-2013), who was engaged in all fields of research
    and promotion of the cultural and environmental heritage of Lessinia, from Geology to Natural
    Sciences, History, traditions. The new species of ammonite named after him, "Benetticeras",
    symbolically represents the 360° interest, the spiritual testament and the heritage that he passed on
    to us and that the Association is committed to enhance and increase towards both the inhabitants
    of Lessinia, tourists and hikers.`
  }
};


export const assGoals = {
  it: {
    slogan: 'I nostri obiettivi',
    goals: [
      'Conservare, promuovere e gestire i Musei della Lessinia',
      'Effettuare e promuovere studi nel campo delle ricerche naturalistiche, geologiche, paleontologiche, preistoriche, speleologiche, paesaggistiche, antropologiche, storiche',
      'Sensibilizzare il pubblico, e soprattutto i giovani, alla valorizzazione e protezione del patrimonio culturale, naturalistico, geologico, paleontologico, preistorico, speleologico, paesaggistico, antropologico, storico della Lessinia',
      'Promuovere iniziative ed attività scientifiche e culturali di salvaguardia del patrimonio naturalistico, ambientale, storico e culturale atte a valorizzare far conoscere gli scopi associativi',
      'Promuovere e coordinare iniziative culturali (convegni, gite, escursioni, spettacoli, manifestazioni culturali, ricreative, mostre, supporto e supervisione di tesi di laurea, ecc.)che meglio servano alla promozione del patrimonio museale e culturale della Lessinia',
      'Produrre materiale editoriale didattico, promozionale, tecnico e scientifico relativo ai musei e alla cultura della Lessinia',
      'Organizzare manifestazioni di promozione dei musei e convegni culturali sulla Lessinia',
      'Promuovere, conservare, gestire le visite turistiche nelle strutture museali, sale didattiche, escursioni in siti di interesse naturalistico, paesaggistico e storico',
      'Partecipare a convegni e manifestazioni di carattere scientifico e divulgativo',
      'Collaborare con altre associazioni aventi scopi comuni',
    ]
  },
  en: {
    slogan: 'Our Goals',
    goals: [
      'To preserve, promote and manage the Museums of Lessinia',
      `To carry out and promote studies in the field of naturalistic, geological, paleontological,
      prehistoric, speleological, landscaped, anthropological and historical research`,
      `To sensitize the public, and especially young people, to the enhancement and protection of
      cultural, naturalistic, geological, paleontological, prehistoric, speleological, landscape,
      anthropological, historical heritage of Lessinia`,
      `To promote scientific and cultural initiatives and activities to safeguard the natural,
      environmental, historical and cultural heritage in order to enhance the association's aims`,
      `To promote and coordinate cultural initiatives (conventions, trips, excursions, shows, cultural
events, recreational activities, exhibitions, support and supervision of degree theses, etc.) that
best serve the promotion of museum and cultural heritage of Lessinia`,
      `To produce didactic, promotional, technical and scientific editorial material related to the
      museums and culture of Lessinia`,
      `To organize events to promote the museums and cultural conferences on Lessinia`,
      `To promote, conserve, and manage tourist visits in the museum structures, educational rooms,
and excursions to sites of naturalistic, landscaped and historical interest`,
      'To participate in conferences and events of scientific and divulgative nature',
      `To collaborate with other associations with common purposes`,
    ]
  }
}

export const benetti = {
  it: {
    title: 'Attilio Benetti',
    subTitle: 'UNA VITA DI SCOPERTE',
    text: `Il museo nasce per merito del Cav. A
ttilio Benetti, figura emblematica di come la curiosità del
mondo circostante possa trasformarsi in grande cultura scientifica. Nonostante abbia
frequentato solo le scuole elementari, ha saputo unire alla sua innata curiosità una grande
passione e bravura
che, usufruendo della ricchezza del materiale paleontologico locale, gli ha
consentito di diventare uno specialista in paleontologia.
Autore di tante pubblicazioni scientifiche e vari libri sulle tradizioni popolari, si è interessato
di geologia, magnetostratigrafia, speleologia, antropologia e leggende popolari ed è rimasto
fino all’ultimo, ovvero fino ai suoi 89 anni in stretti rapporti con università e studiosi.
Il museo ed il Covolo di Camposilvano rappresentano la sua eredità per le future generazioni.`
  },
  en: {
    title: 'Attilio Benetti',
    subTitle: 'A LIFE OF DISCOVERY',
    text: `The museum was created thanks to Cav. Attilio Benetti, an emblematic figure that has shown
    through his life how curiosity can bring to great scientific discoveries. Despite having attended
    only primary schools, he was
    able to combine his innate curiosity with a great passion for
    Nature. Thanks to the abundant fossils
    characterising
    the area he has become one of the most
    renown
    specialist in
    palaeontology
    in Italy. Author of several scientific publications and various
    books on popular traditions, he has been
    studying geology, magnetostratigraphy, speleology,
    anthropology and folk legends and has remained until the last years in close relationships with
    universities and scholars. The Museum and the Covolo di Camposilvano represent his legacy for
    future generations`
  }
}
