/** @jsx jsx */
import { jsx, Container, Box, Grid, Text } from 'theme-ui';
import TextFeature from './text-feature';
import { benetti } from 'content/about.js';
import PicturesGrid from './pictures-grid';

export default function Author({ lang = 'it' }) {
  return (
    <Container sx={styles.containerBox}>
      <Box sx={styles.contentBox}>
        <Box sx={styles.headingTop}>
          <TextFeature subTitle={benetti[lang]?.subTitle} title={benetti[lang]?.title} />
        </Box>
        <Grid gap="15px 0" columns={1} sx={styles.grid}>
          <Box sx={styles.card}>
            <Box sx={styles.wrapper}>
              <Text>
                {benetti[lang]?.text}
              </Text>
            </Box>
          </Box>
        </Grid>
      </Box>
      <PicturesGrid />
    </Container>
  );
}

const styles = {
  containerBox: {
    mt: [8, null, null, null, 9],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: ['column', null, null, 'row'],
  },
  contentBox: {
    width: ['100%', 450, 550, 350, 500, 570],
    pr: [0, null, 'auto', null, null],
    pl: 'auto',
    flexShrink: 0,
  },
  headingTop: {
    pl: [0, null, null, null, '35px', null, '55px', 6],
    mb: [3, null, null, null, 1],
    textAlign: ['center', null, null, 'left'],
  },
  grid: {
    p: ['0 0px 35px', null, null, null, '0 20px 40px', null, '0 20px 40px', 0],
  },
  card: {
    display: 'flex',
    alignItems: 'flex-start',
    p: [
      '0 17px 20px',
      null,
      null,
      '0 0 20px',
      '20px 0px 17px',
      null,
      null,
      '25px 30px 23px',
    ],
    backgroundColor: 'white',
    borderRadius: '10px',
    transition: 'all 0.3s',
    width: ['100%', '85%', null, '100%'],
    mx: 'auto',
    '&:hover': {
      boxShadow: [
        '0px 0px 0px rgba(0, 0, 0, 0)',
        null,
        null,
        null,
        '0px 8px 24px rgba(69, 88, 157, 0.07)',
      ],
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    mt: '-5px',
    title: {
      fontSize: 3,
      color: 'heading_secondary',
      lineHeight: 1.4,
      fontWeight: 700,
      mb: [2, null, null, null, 3],
    }
  },
};
