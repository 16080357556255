/** @jsx jsx */
import { jsx, Container, Flex, IconButton , Grid, Link} from 'theme-ui';
import { AiFillPhone, AiFillMail } from 'react-icons/ai';

import TimeTableCard from '../components/cards/timetable-card';
import SectionHeader from '../components/section-header';
import PatternBG from '../assets/patterns/patternBG.png';
import {content} from '../content/visit.js'

export default function Visit({ lang = 'it' }) {
  return (
    <section id="visit" sx={{ variant: 'section.mainSection' }}>
      <Container sx={styles.timePrice}>
        <SectionHeader
          title={content[lang]?.title}
          isWhite={true}
        />
        <Flex
          sx={{
            justifyContent: 'center',
            flexWrap: ['wrap', null, null, 'nowrap'],
          }}
        >
          {content[lang]?.cards.map((packageData) => (
            <TimeTableCard data={packageData} icon={packageData.icon} key={packageData.name} />
          ))}
        </Flex>
        <Grid gap="15px 0" columns={[1, null, 2]}>
          <Flex sx={{ mb: 1, justifyContent: 'center' , color: '#fff'}} >
            <IconButton sx={styles.icon}>
              <AiFillPhone />
            </IconButton>
            <Link sx={styles.link} href="tel:0456516005">045 6516005</Link>
          </Flex>
          <Flex sx={{ mb: 1, justifyContent: 'center' , color: '#fff'}} >
            <IconButton sx={styles.icon}>
              <AiFillMail />
            </IconButton>
            <Link sx={styles.link} href="mailto:fossili.camposilvano@libero.it">fossili.camposilvano@libero.it</Link>
          </Flex>
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  timePrice: {
    maxWidth: '100% !important',
    backgroundColor: 'primary',
    backgroundImage: `url(${PatternBG})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    py: [8, null, 7],
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      right: 0,
      background:
        'linear-gradient(-45deg, rgba(42,72,125, 0.3) 25%, transparent 25%, transparent 50%, rgba(42,72,125, 0.3) 50%, rgba(42,72,125, 0.3) 75%, transparent 75%, transparent)',
      width: '100%',
      backgroundSize: '350px 350px',
      height: '100%',
      opacity: 0.3,
      zIndex: 0,
    },
  },
  icon: {
    width: [25, '35px'],
    height: 'auto',
    //color: 'primary',
    padding: 0,
    fontSize: [2, 5],
    marginLeft: '-1px',
    flexShrink: 0,
    justifyContent: 'flex-start',
    marginTop: [1, '2px'],
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  }
};
