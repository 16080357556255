/** @jsx jsx */
import { rgba } from 'polished';
import { useState, useRef, useEffect } from 'react';
import { jsx, Box, Container, Image, Text } from 'theme-ui';
import SwiperCore, { Autoplay, Pagination, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import SectionHeader from '../components/section-header';
import Progressbar from '../components/progressbar';
import {data} from '../content/museum';
import Author from '../components/author';
import Camposilvano from '../components/camposilvano';

SwiperCore.use([Autoplay, Pagination, EffectFade]);

const Museum = ({lang='it'}) => {
  const isPause = useRef(false);
  const swiperRef = useRef(null);
  const [togglePlay, setTogglePlay] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  let time = 3;
  let tick, percentTime;

  function startProgressbar() {
    resetProgressbar();
    percentTime = 0;
    tick = setInterval(interval, 40);
  }

  function interval() {
    if (isPause.current === false) {
      percentTime += 1 / (time + 0.1);
      setCurrentWidth(percentTime);
      if (percentTime >= 100) {
        swiperRef.current && swiperRef.current.swiper && swiperRef.current.swiper.slideNext();
        startProgressbar();
      }
    }
  }

  const handleOnSlideChange = (data) => {
    if (data.activeIndex && data.activeIndex !== currentIndex)
      setCurrentIndex(data.activeIndex)
  }

  function resetProgressbar() {
    setCurrentWidth(0);
    clearTimeout(tick);
  }

  useEffect(() => {
    startProgressbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = () => {
    isPause.current = !isPause.current;
    setTogglePlay((prev) => !prev);
  };

  const currentElement = data.gallery.find(el => el.id === currentIndex) || data.gallery[0]
  return ( 
    <Box id="museum" as="section"  sx={{ variant: 'section.mainSection' }} >
      <Container>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.leftContent}>
            <SectionHeader
              sx={styles.heading}
              title={currentElement.title[lang] || ''}
              slogan="il museo"
            />
            <Box sx={styles.featureWrapper}>
              <Text>
                {currentElement.desc[lang] || ''}
              </Text>
            </Box>
          </Box>
          <Box sx={styles.rightContent}>
            <Progressbar
              sx={styles.progressbar}
              togglePlay={togglePlay}
              handleClick={handleToggle}
              currentWidth={currentWidth}
            />
            <Swiper
              loop={true}
              effect="fade"
              ref={swiperRef}
              autoHeight
              spaceBetween={0}
              slidesPerView={1}
              onSlideChange={handleOnSlideChange}
              pagination={{clickable: true}}
            >
              {data?.gallery?.map((item) => (
                <SwiperSlide key={item.id}>
                  <Box as="figure" sx={styles.image}>
                    <Image loading="lazy" src={item.image} alt="" />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      </Container>
      <Author lang={lang} />
      <Camposilvano lang={lang} />
    </Box>
  );
};

export default Museum;

const styles = {
  contentWrapper: {
    gap: [0, 0, 0, 0, '30px'],
    display: ['flex', null, null, null, 'grid'],
    alignItems: 'center',
    flexDirection: ['column', null, null, null, null],
    gridTemplateColumns: ['unset', null, null, null, 'repeat(2,1fr)'],
  },
  leftContent: {
    m: [0, '30px 0px 0', '30px 50px 0', 0],
  },
  heading: {
    textAlign: ['center', null, null, null, 'left'],
    maxWidth: 490,
    margin: ['0 auto 40px', null, null, null, '0 0 80px'],
    h2: {
      fontSize: [22, null, null, 30, 30, 36, 46],
      lineHeight: [1.6, null, null, 1.41],
      fontWeight: [500, null, null, 400],
    },
    p: {
      fontSize: ['15px', null, null, '17px'],
      mt: [3, 3, 3, 5],
    },
  },
  featureWrapper: {
    height: [330, null, 180],
    justifyContent: ['unset', null, null, 'center', 'flex-start'],
  },
  rightContent: {
    //height: '50vh',
    position: 'relative',
    mt: [6, 6, 6, 6, 0],
    maxWidth: '100%',
    '.swiper-pagination-bullets': {
      bottom: 20,
    },
    '.swiper-pagination-bullet': {
      backgroundColor: 'rgba(255,255,255,0.5)',
      width: 10,
      height: 10,
      opacity: 1,
      mx: 5,
      ':focus': {
        outline: 0,
      },
    },
    '.swiper-pagination-bullet-active': {
      backgroundColor: 'rgba(255,255,255,1)',
    },
  },
  progressbar: {
    position: 'absolute',
    padding: '5px 10px',
    borderRadius: '0 10px 10px 0',
    left: [0],
    top: [46, 53],
    zIndex: 2,
  },
  progressBar: {
    position: 'relative',
    mr: 4,
  },
  toggleButton: {
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    display: 'flex',
    padding: 0,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '&:focus': {
      outline: '0 none',
    },
  },
  image: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    backgroundColor: '#000',
    borderRadius: 10,
    '> img': {
      borderRadius: 10,
     height: '50vh',
     width: '100%',
     objectFit: 'contain'
    },
    figcaption: {
      backgroundColor: 'primary',
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      left: 0,
      top: 30,
      borderRadius: 5,
      color: '#fff',
      padding: ['20px 20px 20px 80px', '25px 25px 25px 90px'],
      h4: {
        lineHeight: 1,
        fontWeight: 'bold',
        fontSize: [14, 18],
        letterSpacing: 'heading',
      },
      p: {
        fontSize: [13, 16],
        fontWeight: 500,
        letterSpacing: 'heading',
        color: rgba('#fff', 0.6),
        marginTop: [2],
      },
    },
  },
};
