import bolca from '../assets/partners/bolca.jpeg';
import montecapriolo from '../assets/partners/montecapriolo.jpeg';
import setteVulcani from '../assets/partners/setteVulcani.jpeg';
import santanna from '../assets/partners/santanna.jpeg';

export const content = {
  title: {
    it: 'VISITA ANCHE',
    en: 'PARTNERS'
  },
  partners: [
    {
      title: {
        it: `Museo Sant'Anna`,
        en: `Sant'Anna Museum`
      },
      img: santanna,
      link: `https://www.museosantanna.it`
    },
    {
      title: {
        it: `Cammino dei sette vulcani`,
        en: `Cammino 7 vulcani`
      },
      img: setteVulcani,
      link: 'https://www.camminodeisettevulcani.it'
    },
    {
      title: {
        it: `Museo Fossili Bolca`,
        en: `Bolca`
      },
      img: bolca,
      link: 'https://www.museofossilibolca.eu'
    },
    {
      title: {
        it: `Grotta del monte Capriolo`,
        en: `Grotta del monte Capriolo`
      },
      img: montecapriolo,
      link: `https://www.comune.rovereveronese.vr.it/c023067/zf/index.php/servizi-aggiuntivi/index/index/idtesto/6`
    }
  ]
}