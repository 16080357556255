/** @jsx jsx */
import { jsx, Image, Container, Text, Box } from 'theme-ui';

import SectionHeader from '../components/section-header';
import { content } from '../content/partners';
import { Link } from '../components/link';

export default function Partners({ lang = 'it' }) {
  return (
    <section sx={{ variant: 'section.partners' }} id="partners">
      <SectionHeader
        slogan={content.title[lang]}
        title=""
      />
      <Container sx={styles.container}>
        {content.partners.map(({ title, img, link }, i) => (
          <Link path={link} target="_blank" sx={styles.card} >
          <Box as='div'>
            <Image src={img} sx={styles.img} />
            <Text>
              {title[lang]}
            </Text>
            </Box>
          </Link>
        ))}
      </Container>
    </section>
  );
}

const styles = {
  container: {
      display: 'grid',
      gridTemplateColumns: ['1fr',  'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)','repeat(4, 1fr)'],
      gridTemplateRows: ['repeat(4, 1fr)','repeat(2, 1fr)','repeat(2, 1fr)', 'repeat(2, 1fr)', '1fr'],
      gridColumnGap: 50,
      gridRowGap: [50, 50, 50, 50, 0],
    justifyContent: 'space-between'
  },
  card: {
    '&:hover':{
      boxShadow: '0px 8px 24px #C59B76'
    },
    borderRadius: 5,
    padding: 3,
    textAlign: 'center',
    color: 'primary',
    textDecoration: 'none',
    textTransform: 'uppercase'
  },
  img: {
    borderRadius: 5,
    height: '22vh',
    objectFit: 'contain'
  }
};
