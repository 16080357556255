/** @jsx jsx */
import { jsx, Container, Box, Image, IconButton, Flex } from 'theme-ui';
import { BsGeoAlt } from 'react-icons/bs';

import SectionHeader from '../components/section-header'
import Map from '../assets/images/camposilvano_map.svg'

import { Link } from '../components/link';

const museumAddress = "https://www.google.com/maps/place/Museo+Geopaleontologico+di+Camposilvano/@45.6257351,11.0901704,17z/data=!4m9!1m2!2m1!1sVia+Covolo,+1+-+37030+Camposilvano+di+Velo+Veronese+(VR)!3m5!1s0x477f55a92370e7bf:0xd9e989c1df4525c6!8m2!3d45.6253292!4d11.0917561!15sCjhWaWEgQ292b2xvLCAxIC0gMzcwMzAgQ2FtcG9zaWx2YW5vIGRpIFZlbG8gVmVyb25lc2UgKFZSKVo1IjN2aWEgY292b2xvIDEgMzcwMzAgY2FtcG9zaWx2YW5vIGRpIHZlbG8gdmVyb25lc2UgdnKSARZuYXR1cmFsX2hpc3RvcnlfbXVzZXVtmgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVU5OYVZwVFJtUjNFQUU"
export default function WhereSection({ lang = 'it' }) {

  return (
    <section sx={{ variant: 'section.mainSection' }} id='where'>
      <Container sx={styles.containerBox}>
        <SectionHeader
          slogan={lang === 'it' ? "Dove Siamo" : 'Where to find us'}
          title=""
        />
        <Flex sx={{ mb: 1 }}>
          <Link path={museumAddress} target="_blank" sx={styles.link}>
            <IconButton sx={styles.geoIcon}>
              <BsGeoAlt />
            </IconButton>
            Via Covolo, 1 - 37030 Camposilvano di Velo Veronese (VR)
          </Link>
        </Flex>
        <Box sx={styles.thumbnail}>
          <Image src={Map} alt={'camposilvano_map'} sx={styles.img} />
        </Box>
      </Container>
    </section>
  );
}
const styles = {
  link:{
    color: 'text',
    textDecoration: 'none',
  },
  containerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: ['wrap', null, null, 'nowrap'],
  },
  thumbnail: {
    px: [0, null, '40px', 0],
    //  width: ['100%', null, null, 360, 550, 655, null, 765],
    width: ['100%', null, null, 780]

  },
  img: {
    height: '100%',
    width: '100%'
  },
  geoIcon: {
    width: [25, '35px'],
    height: 'auto',
    color: 'primary',
    padding: 0,
    fontSize: [2, 5],
    marginLeft: '-1px',
    flexShrink: 0,
    justifyContent: 'flex-start',
    marginTop: [1, '2px'],
  },
};

