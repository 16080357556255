/** @jsx jsx */
import { jsx, Container, Box, Grid, Text, Image } from 'theme-ui';
import TextFeature from '../components/text-feature';
import { mainData } from '../content/about.js';
import Logo from '../assets/logo_associazione.png';

import AssTargets from '../components/ass-targets';

export default function About({ lang = 'it' }) {

  return (
    <section sx={{ variant: 'section.mainSection' }} id="about">
      <Container sx={styles.containerBox}>
        <Box sx={styles.thumbnail}>
          <Image sx={styles.img} src={Logo} alt="logo" />

        </Box>
        <Box sx={styles.contentBox}>
          <Box sx={styles.headingTop}>
            <TextFeature subTitle={mainData[lang].subTitle} title={mainData[lang].title} />
          </Box>

          <Grid gap="15px 0" columns={1} sx={styles.grid}>
            <Box sx={styles.card}>
              <Box sx={styles.wrapper}>
                <Text>
                  {mainData[lang]?.par1}
                </Text>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
      <Box sx={styles.description}>
        <Text sx={styles.wrapper.description}>
          {mainData[lang]?.par2}
        </Text>
      </Box>
      <AssTargets lang={lang} />
    </section>
  );
}

const styles = {
  img: {
    height: 140,
  },
  containerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: ['column', null, null, 'row'],
  },
  thumbnail: {
    pl: [0, 5, 0, null, 7, 95],
    pr: [0, 5, null, null, null, 75, 95],
    order: [2, null, null, 0],
    width: ['100%', null, 505, 360, 460, 570, null, 670],
  },
  contentBox: {
    width: ['100%', 450, 550, 350, 500, 570],
    pr: [0, null, 'auto', null, null, 80],
    pl: 'auto',
    flexShrink: 0,
  },
  headingTop: {
    pl: [0, null, null, null, '35px', null, '55px', 6],
    mb: [3, null, null, null, 1],
    textAlign: ['center', null, null, 'left'],
  },
  grid: {
    p: ['0 0px 35px', null, null, null, '0 20px 40px', null, '0 40px 40px', 0],
  },
  card: {
    display: 'flex',
    alignItems: 'flex-start',
    p: [
      '0 17px 20px',
      null,
      null,
      '0 0 20px',
      '20px 15px 17px',
      null,
      null,
      '25px 30px 23px',
    ],
    backgroundColor: 'white',
    borderRadius: '10px',
    transition: 'all 0.3s',
    width: ['100%', '85%', null, '100%'],
    mx: 'auto',
    '&:hover': {
      boxShadow: [
        '0px 0px 0px rgba(0, 0, 0, 0)',
        null,
        null,
        null,
        '0px 8px 24px rgba(69, 88, 157, 0.07)',
      ],
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    mt: '-5px',
    title: {
      fontSize: 3,
      color: 'heading_secondary',
      lineHeight: 1.4,
      fontWeight: 700,
      mb: [2, null, null, null, 3],
    },
    description: {
      px: [5, null, null, null, 8]
    }
  },
};
