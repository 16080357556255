export const camposilvano = {
  title: {
    it: 'CAMPOSILVANO E DINTORNI',
    en: 'CAMPOSILVANO AND SURROUNDINGS'
  },
  text: {
    it: `Dal museo partono vari sentieri che permettono di fare escursioni tra verdi pascoli, boschi di
     faggio e rocce particolari portando a contrade tipiche, malghe, rifugi e punti ristoro dove
     gustarsi i buoni prodotti locali. Bella è anche l’escursione nella suggestiva Valle delle Sfingi: qui,
     monoliti di pietra della forma a fungo ci ricordano le sfingi egizie. Essi sono il risultato del diverso grado di erosione carsica delle due diverse formazioni rocciose che 
     li costituiscono, il Rosso Ammonitico e l’Oolite di San Vigilio. Si possono organizzare visite guidate per 
     scuole e gruppi sia al museo, che nel territorio circostante.`,
    en: `Some trails departing from the Museum allow to trek among green pastures, beech forests and
     peculiar geological features, also leading to the typical villages of Lessinia (contrade), mountain
     huts (malghe), and refreshment points where it is possible to enjoy the good local products.
     The excursion at the small “Valley of the Sphinxes” allows walking among large blocks of rock,
     whose morphology reminds the Egyptian sphinxes: these shapes form because of the different
     response to karst erosion existing between the rock formations of the Rosso Ammonitico and the
     Oolite of San Vigilio.
     Visits for groups and school groups to the Museum, to the Covolo cave and in the surrounding
     areas are provided upon reservation.`
  }
}

export const trail = {
  title: {
    it: 'SENTIERO DELLA GROTTA DEL COVOLO',
    en: `COVOLO CAVE'S TRAIL`
  },
  text: {
    it: `Dal museo parte un sentiero delimitato da una staccionata, che in circa 500 metri, con gradini in salita
    e poi in discesa, conduce all’interno dell’anfiteatro del Covolo di Camposilvano. Si tra tta di una 
    grande caverna parzialmente crollata, formata dai fenomeni carsici che hanno disciolto la roccia
     calcarea nel corso dei millenni Lungo tale percorso sono stati rinvenuti resti risalenti all’epoca 
     romana e longobarda, mentre leggende locali narra no anche di una visita di Dante Alighieri che qui 
     avrebbe trovato ispirazione per la descrizione dei gironi dell’Inferno nella Divina Commedia.`,
    en: `From the Museum, a gently steep trail, with steps and bordered by a fence, drives into the Covolo of 
    Camposilvano amphitheatre , in about five hundred meters. This s the remain of giant cavenr partially 
    collapsed in the millenia formed by karstic processes that have dissolved the limestone. Along the 
    trail, arch aeologists have found traces of Romans and ancient Lombard frequentation, while local 
    legends assure that the writer Dante Alighieri visited the area and found inspiration here for the 
    description of the descent into the Hell of the “Divina Commedia” poem.`
  }
}
export const cave = {
  title: {
    it: 'GROTTA DEL COVOLO',
    en: 'COVOLO CAVE'
  },
  text: {
    it: `Si tratta di una cavità carsica particolarmente suggestiva, profonda oltre 80 metri. In tempi lontani era un’enorme 
    grotta sotteranea a cui in seguito è crollata la volta superiore composta dalla formazione rocciosa del 
    rosso ammonitico. Sono stati trovati oltre a fossili di ammoniti resti del passaggio dell’uomo preistorico 
    esposti al museo. Sul fondo del Covolo è spesso presente un deposito di ghiaccio da cui Dante avrebbe preso
     ispirazione per descrivere il lago ghiacciato del Cocito.`,
    en: `The Covolo is a really suggestive karstic cavity, over 80 meters deep, constituting the residual cave of 
    an ancient huge an underground cave, which upper vault, constituted by strata of the Rosso Ammonitico rock 
    formation, collapsed. At the bottom of the cave during specific periods of the year it is possible to observe 
    a ice deposit from which Dante took inspiration for describing the frozen lake of Cocito.`
  }
}

