/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import Masonry from 'react-masonry-component';
import GalleryCard from './cards/gallery-card';

import gallery1 from '../assets/benetti/gal.jpg';
import gallery2 from '../assets/benetti/fossil.jpg';
import gallery3 from '../assets/benetti/dig.jpg';

const data = [
  {
    id: 1,
    image: gallery1,
    "title-it": 'Label foto 1',
    "title-en": 'Label pic 1',
  },
  {
    id: 2,
    image: gallery2,
    "title-it": 'Label foto 2',
    "title-en": 'Label pic 2',
  },
  {
    id: 3,
    image: gallery3,
    "title-it": 'Label foto 3',
    "title-en": 'Label pic 3',
  },
];

const masonryOptions = {
  transitionDuration: 0,
};

const PicturesGrid = ({ lang = 'it' }) => {
  return (
    <Box id="gallery" sx={styles.section}>
      <Container sx={styles.container}>
        <Box as={Masonry} options={masonryOptions} sx={styles.galleryWrapper}>
          {data?.map((item) => (
            <GalleryCard key={item.id} item={item} lang={lang} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default PicturesGrid;

const styles = {
  section: {
    width: '100%',
    height: '100%',
  },
  heading: {
    mb: [30, 30, 40, 60],
  },
  galleryWrapper: {
    mx: '-15px',
  },
  button: {
    minHeight: [50, 50, 50, 60],
    fontSize: [14, 14, 16],
    width: '100%',
    svg: {
      transition: 'margin-left 0.3s ease-in-out 0s',
    },
    ':hover': {
      svg: {
        ml: '5px',
      },
    },
  },
};
