/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Box, Heading, Text, Image } from 'theme-ui';
import BannerBG from '../assets/patterns/bannerBg.png';
import BannerImg from '../assets/images/banner-museo2.jpg';

const data = {
  it: {
    title: 'Museo Geopalentologico di Camposilvano',
    descr: `Un viaggio sulle tracce di epoche passate, tra rocce e grotte della Lessinia, scoprendo
    ammoniti, impronte di dinosauro, orsi delle caverne e antichi villaggi preistorici.`
  },
  en: {
    title: 'Geological and Paleontological Museum',
    descr: `A
    journey through ancient geological times, among rocks and caves of the Lessini Mountains,
    discovering ammonites, dinosaur footprints, cave bears and ancient prehistoric villages.`
  }
}

export default function Banner({ lang = 'it' }) {

  return (
    <section sx={styles.banner} id="home">
      <Container sx={styles.banner.container}>
        <Box sx={styles.banner.contentBox}>
          <Heading as="h1" variant="heroPrimary" >
            {data[lang]?.title}
          </Heading>
          <Text as="p" variant="heroSecondary">
            {data[lang]?.descr}
          </Text>
        </Box>
        <Box sx={styles.banner.imageBox}>
          <Image className='banner-img' src={BannerImg} alt="logo" />
        </Box>
      </Container>
    </section>
  );
}

const styles = {
  banner: {
    overflow: ['hidden', 'initial', null, 'hidden'],
    backgroundImage: `url(${BannerBG})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: 'top left',
    backgroundSize: 'cover',
    borderBottomRightRadius: [100, 150, null, null, null, 250],
    pt: ['150px', null, null, null, null, null, '140px', '130px'],
    pb: ['100px', null, null, '110px', null, 10, '150px'],
    backgroundColor: 'primary',
    container: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    contentBox: {
      width: ['100%', null, '85%', '55%', '50%', '55%'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexShrink: 0,
      pt: [0, null, null, null, null, null, 5, 7],
    },
    imageBox: {
      display: ['none', null, null, null, 'block'],
      justifyContent: 'center',
      overflow: 'hidden',
      textAlign: 'right',
      width: '40vw',
      height: '85vh',
      borderRadius: '50% 50% 50% 50% / 60% 60% 40% 40%'
    },
  },
  sponsorTitle: {
    color: 'white',
    fontSize: [1, 2],
    opacity: 0.6,
    pr: 20,
    flexShrink: 0,
    pb: [2, null, 0],
  },
  sponsorBox: {
    pt: ['35px', null, null, null, null, '45px'],
    flexDirection: ['column', null, 'row'],
    sponsor: {
      display: 'flex',
      alignItems: 'center',
      '> a': {
        mr: [5, null, null, 4, 6],
        display: 'flex',
        '&:last-of-type': {
          mr: 0,
        },
      },
    },
  },
};
