import React from 'react';
import { GiDinosaurBones, GiAmmonite } from 'react-icons/gi';

export const content = {
  it: {
    title: 'VISITE',
    cards: [
      {
        name: 'ORARI',
        icon: <GiDinosaurBones />,
        points: [
          'Dal 15 giugno alla prima settimana di settembre aperto tutti i giorni escluso il lunedì, con i seguenti orari: 9.30-12.30/15.00-18.00.',
          `Dalla prima settimana di settembre al 15 gennaio e dal 15 marzo al 14 giugno aperto il sabato, la domenica e festivi con i seguenti orari: 10.00 - 12.00/14.30 - 16.30.`,
          `Il Museo è chiuso da metà gennaio a metà marzo. Aperture straordinarie su prenotazione anche in altri giorni tutto l’anno per scuole e gruppi.`
        ],
      },
      {
        name: 'PREZZI',
        icon: <GiAmmonite />,
        points: [
          'Ordinario: 4.00 €',
          'Ridotto: 3.00 € (per gruppi di minimo 10 unità, bambini dai 6 ai 15 anni, scolaresche, anziani con età superiore ai 70 anni)',
          'Gratuito per diversamente abili e bambini sotto i 6 anni',
        ],
      }
    ]
  },
  en: {
    title: 'VISITS',
    cards: [
      {
        name: 'HOURS',
        icon: <GiDinosaurBones />,
        points: [
          'From the 15 th of June to the first week of september we are open all days with the exception of Monday, with the following times: 9.30 - 12. 30/15.00 - 18.00.',
          `From the first week of September to the 15 th of J anuary and from the 15th of March to the 14th of June we are open on Saturday, Sunday and holidays with the following times: 10.00 - 12.00/14.30 - 16.30.`,
          `The Museum is closed from mid January to mid March. Special openings on request for groups and scholars though all the year.`
        ],
      },
      {
        name: 'PRICES',
        icon: <GiAmmonite />,
        points: [
          'Full price: 4.00 €', 
          'Reduced rate: 3.00 € (groups with 10 people or more, children from 6 to 15 years, scholars, adults over 70 years of age)',
          'Free entry for disabled people and children 6 years or younger',
        ],
      }
    ]
  },
}
